import { Box, Button } from "@mantine/core";
import Link from "next/link";
import Banner from "../components/Banner";
import JobListItem from "../components/JobListItem";
import Newsletter from "../components/Newsletter";
import Seo from "../components/SEO";
import { getAllJobs, getAllOtherJobs, updateOtherJobs } from "../utils/serverSupabase";

export default function Home({ jobs, otherJobs }) {
  return (
    <div>
      <Seo title="React Native jobs from around the world" />
      <Banner />
      <Box>
        {jobs.map((job) => (
          <JobListItem key={job.id} job={job} />
        ))}
      </Box>
      <Newsletter inline />
      <Box>
        {otherJobs.map((job) => (
          <JobListItem isOtherJob key={job.id} job={job} />
        ))}
      </Box>
      <Link href={"/jobs"} passHref>
        <Button component="a" fullWidth size="lg" variant="subtle">
          See all jobs
        </Button>
      </Link>
    </div>
  );
}

export const getStaticProps = async () => {
  const jobs = await getAllJobs();
  await updateOtherJobs();
  const otherJobs = await getAllOtherJobs();
  return {
    props: {
      jobs,
      otherJobs,
    },
  };
};
